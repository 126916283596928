<template>
<div class="h-full w-full min-h-screen relative" >
<img src="@/assets/images/WEB2.jpg" class="fixed h-screen w-full  "/>
<Loading v-show="loading" :loading="loading"/>
  <Container back_ground_color='md:p-20 sm:p-20 h-full xs:p-0 xs:bg-white '>
    <Modal v-show="show_selfie_modal"
        v-bind:display="show_selfie_modal"
        z_value="z-50 m-0"
        :default_width="'max-w-xl static_modal'"
        @closed="show_selfie_modal=false"
        closable
        :title="'Take a Selfie With your ID'"
        id='vote_modal'>
          <div class="w-full p-2" v-if="show_selfie_modal">
                  <TakeAPhoto id="selfie" ref="selfie" required @changed="saveSelfie" v-if="show_selfie_modal"/>
              </div>
    </Modal>
    <Modal v-show="show_aggreement"
        v-bind:display="show_aggreement"
        z_value="z-50 m-0"
        :default_width="'max-w-xl static_modal'"
        :positiveButton="'AGREE'"
        :negativeButton="'CANCEL'"
        @positiveClick="proceedPmes"
        @negativeClick="show_aggreement=false"
        @closed="show_aggreement=false"
        closable
        :title="'AGREEMENT'"
        id='vote_modal'>
          <div class="w-full p-2" v-if="show_aggreement">
              <span class="text-lg" v-if="show_aggreement">
                The Online Membership Application (OMA) shall be valid for a period of 90 days from the Application Date. I agree that my application is pre-approved.
              </span>
          </div>
    </Modal>
    <Modal
    id="terms"
    v-show="show_terms_modal"
    :display="show_terms_modal"
    :z_value="'z-40'"
    :default_width="'max-w-lg'"
    >
    <!-- https://media.pafcpic.net/images/wallpaper/wallpaper-blue.jpg -->
        <div class="flex flex-col w-full space-y-2 p-2" >
          <div class="flex flex-row space-x-2 pt-6 p-2">
            <Checkbox  id="agree_ck" class="text-left mt-auto mb-auto" :checkbox_width="32" @changed="update"/>
            <div class="flex flex-wrap mt-auto mb-auto">
                <span v-text="'I agree with the'"/>
                <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold pl-1" text="End User License"></LinkText>
                <LinkText emit_triggered='OpenTerms' @OpenTerms="OpenTerms" class="text-theme-primary font-bold  pl-1 xs:pl-0" text="Agreement"></LinkText>
                <span v-text="'and'" class="pl-1 pr-1"/>
                <LinkText emit_triggered='OpenPrivacy' @OpenPrivacy="OpenPrivacy" class="text-theme-primary font-bold " text="Privacy Policy"></LinkText>
            </div>
        </div>
          <Button :button_text="'CONTINUE'" :disabled="!aggree" @clicked="proceed"/>
          <!-- <Button :button_text="'BACK'" @clicked="disagree"/> -->
        </div>
    </Modal>
    <Modal
      id="terms"
      v-show="show_pmes_video"
      :display="show_pmes_video"
      :z_value="'z-50'"
      :default_width="'max-w-3xl'"
      :closable="proceed_button"
      @closed="closepmes"
    >
        <div class="flex flex-col w-full relative p-5 " v-if="show_pmes_video">
          <div class="w-[95%] h-[450px] absolute z-40 " :class="[{'bg-black':proceed_button}]"></div>
          <YouTube width='100%' height='450' :vars="{controls:0, autoplay:0 }" src="https://www.youtube-nocookie.com/embed/EZyCYi6-EXI?controls=0" @state-change='videoStop' @ready="onReady" ref="youtube" @playback-quality-change="'highres'"/>
          
          <a-progress :percent="yt_time" size="small"> </a-progress>
          <!-- <iframe width="100%" height="550" ref="player" src="https://www.youtube-nocookie.com/embed/-0GAC6pzeI8?enablejsapi=1&autoplay=1" title="PMES" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
          <!--  -->
          <div class="w-full p-2"  >
              <div class="text-2xl font-bold p-5" v-if="!proceed_button">
                <span v-text="'Please watch this video to proceed '"/>
              </div>
            <Button :button_text="'PLAY VIDEO'" @clicked="startVideo" v-show="false" v-if="!video_playing"/>
            <Button :button_text="'PROCEED'" @clicked="donePmes" v-show="proceed_button"/>
          </div>
        </div>
    </Modal>
     <Modal v-show="show_message_modal"
        v-bind:display="show_message_modal"
        :error="this.error"
        :success="this.success"
        z_value="z-50"
        @positiveClick="closed"
        :positiveButton="'OK'"
        :default_width="'max-w-xl'"
        id='vote_modal'>
       <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
            {{ this.modal_message}}
        </div>
    </Modal>
    <Modal v-show="show_back_to_login"
        v-bind:display="show_back_to_login"
        z_value="z-50"
        :default_width="'max-w-xl'"
        id='vote_modal'>
       <div class="w-full p-5 flex flex-col text-2xl font-bold text-theme-primary">
          back to login?
        </div>
         <div class="w-full flex flex-row text-xl  p-2 space-x-1"  >
            <Button :button_text="'YES'" @clicked="backtoLogin"/>
            <Button :button_text="'NO'" @clicked="this.show_back_to_login = false"/>
          </div>
    </Modal>
    <Modal v-show="show_requirements_modal"
        v-bind:display="show_requirements_modal"
        z_value="z-50"
        :default_width="'max-w-3xl'"
        id='vote_modal'>
       
         <!--  -->
       <div class="w-full rounded-t-md text-white p-5 xs:p-2 flex flex-col xs:text-xl text-2xl font-bold bg-theme-primary">
          PAFCPIC ONLINE MEMBERSHIP APPLICATION REQUIREMENTS
        </div>
        <div class="w-full p-0 rounded-t-md relative flex flex-col z-20" >
            <img src="https://media.pafcpic.net/images/logo/pafcpic.png" class="xs:h-[40%] w-[50%] h-[55%] bottom-[35px] right-[0] absolute opacity-20 m-auto" />
        <div class="w-full flex flex-col text-left  p-4">
          <div class="w-full p-1">
             <span v-text="'For PA, PN, PAF:'" class="text-2xl xs:text-xl font-bold w-full"/>
          </div>
          <div class="w-full border-b border-black  p-1 pl-2">
             <span v-text="'1. Military ID'" class="text-xl xs:text-lg w-full"/>
          </div>
           <div class="w-full border-b border-black p-1 pl-2">
             <span v-text="'2. TIN No.'" class="text-xl xs:text-lg w-full"/>
          </div>
        </div>
        <div class="w-full flex flex-col text-left  p-4">
          <div class="w-full p-1">
              <span v-text="'For Dependents (Parents,Spouse,Children):'" class="text-2xl xs:text-xl font-bold"/>
          </div>
          <div class="w-full border-b border-black  p-1 pl-2">
            <span v-text="'1. Sponsor Account No./Serial No.'" class="text-xl xs:text-lg"/>
          </div>
          <div class="w-full border-b border-black  p-1 pl-2">
            <span v-text="'2. Any Government ID'" class="text-xl xs:text-lg"/>
          </div>
          <div class="w-full border-b border-black p-1 pl-2">
            <span v-text="'3. Sponsor ID'" class="text-xl xs:text-lg"/>
          </div>
          <!-- <div class="w-full border-b border-black  p-1 pl-2">
            <span v-text="'4. TIN No.'" class="text-xl xs:text-lg"/>
          </div> -->
        </div>
        <div class="w-full flex flex-col text-left p-4">
          <div class="w-full p-1">
             <span v-text="'For Retired/Pensioner:'" class="text-2xl font-bold xs:text-xl"/>
          </div>
          <div class="w-full border-b border-black p-1 pl-2">
           <span v-text="'1. Pensioner ID'" class="text-xl xs:text-lg"/>
          </div>
          <div class="w-full border-b border-black p-1 pl-2">
           <span v-text="'2. TIN No.'" class="text-xl xs:text-lg"/>
          </div>
        </div>
        </div>
         <div class="w-full flex flex-row text-xl  p-2"  >
            <Button :button_text="'OK'" @clicked="closeRequirements" class="z-50"/>
        </div>
    
    </Modal>
    <div v-show="start" class=" z-30 space-y-5 xs:space-y-0 p-2" >
        <ConfigProvider :theme="this.token" >
          <Steps :current="this.current" size="LARGE"   id="step" class="xs:hidden">
            <a-step class="ant-steps-item-title" v-for="item in steps" :key="item.title" :title="item.title" @click="change(item.content)" >
            </a-step>
          </Steps>
        </ConfigProvider>
        <div class="w-full flex md:flex-row sm:flex-row xs:flex-col items-center justify-center xs:pb-2 pb-16 ">
          <div class="flex w-full md:max-w-xxs sm:max-w-xxs h-full xs:space-x-1 mr-10 xs:mr-0 relative xs:pb-2 pb-16 pr-2 pt-2 xs:hidden">
            <div class="w-full z-40" v-if="current>0">
              <Button :max_width="'sm:max-w-xxs md:max-w-xxs md:rounded-full'" text_size="-mt-4" height='max-h-20 h-20 w-20 text-7xl bg-transparent hover:bg-theme-primary' :button_text="'<'" @clicked="backStep"/>
                
            </div>
          </div>
          <BasicInfo id="bsinfo" ref="bInfo" @loading="sponsorCheckingSerial" @done="sponsorDoneCheckingSerial" v-show="this.basic_info" @hasError="hasError" @numberchange="numberChange" @tinchange="tinchange" @emailchange="emailchange" @clearError="clearError" class="transition h-fit delay-100 duration-500 ease-in-out z-40"
          v-bind:class="[{'-translate-x-0' : this.show_step_one ,'-translate-x-full':!this.show_step_one}]"/>
          <Address id="secinfo" ref="secinfo" v-show="this.address"  class="transition delay-100 duration-500 ease-in-out h-fit z-40"
          v-bind:class="[{'-translate-x-0' : this.show_step_two ,'-translate-x-full':!this.show_step_two}]"/>
          <FamInfo :clearError="checkError" :hasError="checkError" id="faminfo" ref="faminfo" v-show="this.famInfo" class="transition delay-100 duration-500 ease-in-out  h-fit z-40"
          v-bind:class="[{'-translate-x-0' : this.show_step_three ,'-translate-x-full':!this.show_step_three}]"/>
          <OtherInfo id="otherInfo" ref="otherInfo" v-show="this.otherInfo" class="transition delay-100 duration-500 ease-in-out h-fit z-40"
          v-bind:class="[{'-translate-x-0' : this.show_step_four ,'-translate-x-full':!this.show_step_four}]" @saveSelfie="saveSelfie" @openCamera="openCamera"/>
          <Summary v-show="this.summary" ref="summary" class="transition delay-100 duration-500 ease-in-out z-40 h-fit"
          v-bind:class="[{'-translate-x-0' : this.show_summary ,'-translate-x-full':!this.show_summary}]"/>
        
          <div class="flex w-full md:max-w-xxs sm:max-w-xxs h-full xs:space-x-1 relative xs:pb-2 pb-16 pt-2 pl-2 xs:pl-0 ml-10 xs:ml-0">
               <div class="w-full md:hidden sm:hidden" v-if="current>0">
                  <Button :max_width="'sm:max-w-xxs md:max-w-xxs md:rounded-full w-full z-30'" height='max-h-16 h-16' :button_text="'BACK'" @clicked="backStep"/>
              </div>
              <div class="flex sm:justify-end md:justify-end w-full md:hidden sm:hidden"  v-if="!completed && current < 4">
                <Button :max_width="'sm:max-w-xxs md:max-w-xxs md:rounded-full w-full z-30'" :disabled="checking" height='max-h-16 h-16' :button_text="'NEXT'" @clicked="nextStep"/>
              </div>
              <div class="flex sm:justify-end md:justify-end w-full xs:hidden"  v-if="!completed && current < 4">
                <Button :max_width="'sm:max-w-xxs md:max-w-xxs md:rounded-full w-full z-30'" text_size="-mt-4" :disabled="checking" height='max-h-20 w-20 h-20 text-7xl bg-transparent hover:bg-theme-primary' :button_text="'>'" @clicked="nextStep"/>
              </div>
              <div class="flex sm:justify-end md:justify-end w-full xs:hidden"  v-if="completed && current == 4">
                <Button :max_width="'sm:max-w-xss md:max-w-xxs  md:rounded-full w-full z-30'" text_size="-mt-4" height='max-h-20 w-20 h-20 text-7xl bg-transparent hover:bg-theme-primary'  :button_text="'>'" @clicked="submit"/>
              </div>
               <div class="flex sm:justify-end md:justify-end w-full md:hidden sm:hidden"  v-if="completed && current == 4">
                <Button :max_width="'sm:max-w-xxs md:max-w-xxs md:rounded-full w-full z-30'" height='bg-theme-primary max-h-16 h-16'  :button_text="'SUBMIT'" @clicked="submit"/>
              </div>
          </div>
        </div>
       </div>
  </Container>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import BasicInfo from "./MembersBasicInfoView.vue";
import Address from "./MembersAddressView.vue";
import OtherInfo from "./MembersUploadingView.vue";
import FamInfo from "./MembersFamilyView.vue";
import Button from "@/components/controls/ButtonControl.vue"
import Container from '@/components/sections/ContainerView.vue';
import mixin from "@/mixin";
import LinkText from "@/components/controls/LinkText.vue";
import {Steps,ConfigProvider } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import Summary from './MembersSummaryView.vue';
import Modal from '@/components/controls/ModalControl.vue';
import Member from '@/services/Member';
import Loading from '@/components/controls/LoadingControl.vue';
import Checkbox from '@/components/controls/CheckBox.vue';
import User from '@/services/User';
import Otp from '@/services/OTP';
import YouTube from 'vue3-youtube';
import moment from 'moment';
import TakeAPhoto from '@/components/controls/TakeAPhoto.vue';
export default defineComponent({
  components:{
    Container,
    BasicInfo,
    FamInfo,
    Summary,
    Address,
    ConfigProvider,
    OtherInfo,
    Loading,
    LinkText,
    Steps,
    Button,
    Modal,
    Checkbox,
    YouTube,
    TakeAPhoto
  },
  mixins: [
    mixin,
  ],
  mounted(){
    this.proceed_button =false;
    this.basic_info=true;
    // this.openCamera=false;
    // this.pmesVid();
    this.proceed();
  },
  data(){
    return{
      token:{
        colorPrimary:"#016b00"
      },
      image:'background-image: url(\'' + require('@/assets/images/WEB2.jpg') + '\'); background-repeat: no-repeat; background-position: center; width:100%; background-size: contain;',
      show_back_to_login:false,
      current:0,
      has_error:false,
      basic_info:false,
      proceed_button:false,
      show_pmes_video:false,
      show_step_one:false,
      show_step_two:false,
      show_step_three:false,
      show_step_four:false,
      show_summary:false,
      checking:false,
      step_one_done:false,
      step_step_two:false,
      step_step_three:false,
      show_message_modal:false,
      show_selfie_modal:false,
      video_playing:false,
      completed:false,
      address:false,
      time:null,
      aggree:false,
      start:false,
      loading:false,
      otherInfo:false,
      famInfo:false,
      show_terms_modal:false,
      summary:false,
      success:false,
      error:false,
      familyError:false,
      valid_number:false,
      show_requirements_modal:false,
      valid_email:false,
      valid_tin:false,
      interval:null,
      show_aggreement:false,
      yt_time:0,
      modal_message:'',
      errors_log:[],
      current_time:0,
      previous_step_error:false,
      previous_step:null,
      steps:[
            {
              title:"Personal Information",
              content:"bsinfo",
              active:true,
            },
            {
              title:"Address",
              content:"secinfo",
              active:true,
            },
            {
              title:"Family Information",
              content:"faminfo",
              active:true,
            },
            {
              title:"Other Information",
              content:"otherinfo",
              active:true,
            },
            {
              title:"Summary",
              content:"summary",
              active:true,
            }
          ]
    }
  },
  methods:{
      sponsorCheckingSerial(){
        this.checking = true;
      },
      closeRequirements(){
        this.start = true;
        this.show_requirements_modal = false;
      },
      sponsorDoneCheckingSerial(){
        this.checking = false;
      },
      startVideo(){
        this.video_playing = true;
        this.$refs.youtube.playVideo();
      },
      checkError(e){
        this.familyError = e;
      },
     onReady() {
            this.startVideo();
            // this.$refs.youtube.playVideo();
        },
      saveSelfie(e){
          let that = this;
          let ref = that.$refs;
          let store = that.$store;
          let state = store.state;
          that.show_selfie_modal = false;
          ref.otherInfo.selfie = e.value;
          state.membership_details.snap = e.value;
      },
    videoStop(){
      let that = this;
       if(that.$refs.youtube.getPlayerState()>0){
         that.video_playing = true;
       }else{
         that.video_playing = false;
       }
       if(that.$refs.youtube.getCurrentTime()>=517){
        that.proceed_button =true;
        // t.stop();
        clearInterval(that.interval);

       }else{
        that.interval = setInterval(function(){
          let time = parseInt(that.$refs.youtube.getCurrentTime());
                that.yt_time = parseInt((parseFloat((time/517)).toFixed(2)) *100);
        }, 1000);
       }
    },
    disagree() {
      this.$router.push('/');
    },
    showBackToLogin(){
      this.show_back_to_login = true;
    },
    backtoLogin(){
      this.$router.push('/');
    },
    update(e){
      this.aggree = e;
    },
    pmesVid(){
      let that = this;
      that.show_pmes_video = true;

    },
    donePmes(){
     let that = this;
     let store = that.$store;
     let state = store.state.membership_details;
      that.loading = true;
      that.show_pmes_video = false;
      state.pmes_dt = moment(Date.now()).format('YYYY-MM-DD hh:mm:ss');
      Otp.requestOtp({type:11,email:state.email,name:state.first_name}).then(function(response) {
        state.email_pin = response.data.data.pin;
        Otp.requestOtp({type:12,mobile:state.mobile,name:state.first_name}).then(function(response) {
          that.show_pmes_video = false;
            state.phone_pin = response.data.data.pin;
            that.loading = false;
            that.$router.push('/member/otp');
        }).catch(function(){
            // let error_response = error;
            that.loading = false;
        });
      }).catch(function(){
          // let error_response = error;
          this.loading = false;
      });
    },
    proceed(){
      let that = this;
      let store = that.$store;
      let state = store.state;
      let references = state.references;
      that.loading=true;
      
      Member.getReference().then(function(response) {
          let reference_data = response.data.data;
          let seconds=0;

          references.branch_of_service = reference_data.branch_of_service;
          references.branches = reference_data.branches;
          references.civil_status = reference_data.civil_status;
          references.gender = reference_data.gender;
          references.ranks = reference_data.ranks;
          references.server_time = response.timestamp;
          references.employment_status_options = reference_data.employment_status;
          that.loading=false;
          that.show_requirements_modal =true;
          that.show_terms_modal= false;
          // references.server_time = moment(references.server_time).format('YYYY-MM-DDTHH:mm:ss');
          
          that.time = setInterval(function(){
                seconds++;
                if(seconds==60) {
                  seconds = 0;
                  references.server_time = moment(reference_data.server_time).add(1,'minutes');
                }
                
                
        }, 1000);

      }).catch(function(){
        // let catch_error  = error.response.data;
        that.loading=false;
      });
    },
    closed() {
      this.show_message_modal = false;
      if(this.success){
        this.$router.push('/');
      }
    },
    OpenTerms() {
      this.$emit('OpenTerms');
    },
    OpenPrivacy() {
      this.$emit('OpenPrivacy');
    },
    change(content) {
      let prev = "";
        if(this.current == 0 && this.step_one_done){
              prev = 'bsinfo';
        }
        if(this.current == 1 && this.step_two_done){
              prev = 'secinfo';
        }
        if(this.current == 2  && this.step_three_done){
              prev = 'faminfo';
        }
        if(this.current == 3 && this.step_four_done){
              prev = 'otherinfo';
        }
        if(this.current == 4){
              prev = 'summary';
        }
        this.stepClicked(prev);
       if(content=='bsinfo' && this.step_one_done && !this.previous_step_error){
          this.current=0;
          this.basic_info=true;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=false;
          this.completed=false;
        }
        if(content=='secinfo' && this.step_two_done && !this.previous_step_error){
          this.current=1;
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.summary=false;
          this.famInfo=false;
          this.completed=false;
        }
        if(content=='faminfo' && this.step_three_done && !this.previous_step_error) {
          this.current=2;
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.summary=false;
          this.completed=false;
        }
        if(content=='otherinfo' && this.step_four_done && !this.previous_step_error) {
          this.current=3;
          this.$refs.otherInfo.check();
          this.basic_info=false;
          this.address=false;
          this.otherInfo=true;
          this.summary=false;
          this.famInfo=false;
         
          this.completed=false;
        }
        if(content=='summary' && this.step_three_done && this.step_two_done && this.step_one_done && this.step_four_done && !this.previous_step_error) {
          this.$refs.summary.applyChanges();
          this.current=4;
          this.completed = true;
          this.basic_info=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
          this.famInfo=false;
          
        }
    },
    openCamera(){
      this.show_selfie_modal=true;
    },
    hasError(e){
      this.errors_log.push(e);
    },
    clearError(id) {
       for(let i = 0; i<this.errors_log.length ; i++ )
        {
          if(id == this.errors_log[i]) {
             this.errors_log.splice(i,1)
          }
        }
    },
    stepClicked(content) {
       let ref = this.$refs;
        let store = this.$store;
        let state = store.state.membership_details;
       
        // let reference = store.state.references.ranks[state.branch_of_service];
        if(content == 'bsinfo') {
         
          var selectedDate = new Date(state.birth_date);
          var currentDate = new Date(Date.now());
          var diff = currentDate.getFullYear() - selectedDate.getFullYear();
          if(this.valid_email == false){
            this.validate_dups('email');
          }
          if(this.valid_number == false){
             this.validate_dups('mobile');
          }
          if(this.valid_tin == false && state.branch_of_service != "DEP"){
             this.validate_dups('tin');
          }
          
          if(state.branch_of_service == "DEP"){
             if(state.sss_number.length > 0 && state.gsis_number.length > 0) {
              // && this.valid_tin
              if(this.valid_number && this.valid_email  && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
              && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.exist
              && state.gender != null  && (state.sponsor_name != '' || state.sponsor_name != null) && (state.sponsor_nr != '' || state.sponsor_nr != null) &&
              (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
              && state.sss_number.length == 9 && state.gsis_number.length == 8) {

                  this.previous_step_error = false;
                  this.previous_step = content;
              }else{
                this.previous_step_error = true;
                this.previous_step = content;
                ref.bInfo.checkFields();
                ref.bInfo.checkLength();
                
              }
          }
          else if(state.gsis_number.length > 0) {
            //  && this.valid_tin
            if(this.valid_number && this.valid_email && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null  &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.gsis_number.length == 8) {
               this.previous_step_error = false;
               this.previous_step = content;
               ref.bInfo.checkLengthGSIS();
            }else{
              this.previous_step_error = true;
              this.previous_step = content;
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthGSIS();
            }
          } 
          else if(state.sss_number.length > 0){
            // && this.valid_tin
            if(this.valid_number && this.valid_email  &&  (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9) {
                ref.bInfo.checkLengthSSS();
                 this.previous_step_error = false;
                 this.previous_step = content;
            }else{
               this.previous_step_error = true;
               this.previous_step = content;
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthSSS();
            }
          }
          if(state.sss_number.length == 0 && state.gsis_number.length == 0){
            // && this.valid_tin
            if(this.valid_number && this.valid_email  && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email)) {
                 this.previous_step_error = false;
                 this.previous_step = content;
            }else{
               this.previous_step_error = true;
               this.previous_step = content;
              ref.bInfo.checkFields();
              
            }
          }

          //else here for DEP IF
          } else {
            
            if(state.sss_number.length > 0 && state.gsis_number.length > 0) {
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth  && (state.minimum_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
           (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9 && state.gsis_number.length == 8) {
              if(state.rank_required){
                if(state.rank != null){
                      ref.bInfo.checkLength();
                       this.previous_step_error = false;
                       this.previous_step = content;
                }else{
                   this.previous_step_error = true;
                   this.previous_step = content;
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                  ref.bInfo.checkLength();
                  this.previous_step_error = false;
                  this.previous_step = content;
              }
            }else{
               this.previous_step_error = true;
               this.previous_step = content;
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          }
          else if(state.gsis_number.length > 0) {
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
           (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.gsis_number.length == 8) {
             if(state.rank_required){
                if(state.rank != null){
                      ref.bInfo.checkLength();
                       this.previous_step_error = false;
                       this.previous_step = content;
                }else{
                   this.previous_step_error = true;
                   this.previous_step = content;
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                  ref.bInfo.checkLength();
                  this.previous_step_error = true;
                  this.previous_step = content;
              }
            }else{
               this.previous_step_error = true;
               this.previous_step = content;
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          } 
          else if(state.sss_number.length > 0) {
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9) {
             if(state.rank_required){
                if(state.rank != null){
                   
                      ref.bInfo.checkLength();
                       this.previous_step_error = false;
                       this.previous_step = content;
                }else{
                   this.previous_step_error = true;
                   this.previous_step = content;
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                  ref.bInfo.checkLength();
                   this.previous_step_error = true;
                   this.previous_step = content;
              }
            }else{
              this.previous_step_error = true;
              this.previous_step = content;
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          }
          if(state.sss_number.length == 0 && state.gsis_number.length == 0){
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email)) {
             if(state.rank_required){
                if(state.rank != null){
                      // ref.bInfo.checkLength();
                       this.previous_step_error = false;
                       this.previous_step = content;
                }else{
                   this.previous_step_error = true;
                   this.previous_step = content;
                  ref.bInfo.checkFields();
                  // ref.bInfo.checkLength();
                }
              }else{
                  // ref.bInfo.checkLength();
                   this.previous_step_error = false;
                   this.previous_step = content; 
              }
            }else{
               this.previous_step_error = true;
               this.previous_step = content;
              ref.bInfo.checkFields();
              // ref.bInfo.checkLength();
            }
          }
          }
        }
        if(content == 'secinfo') {
           if(state.resstreet_address != null && state.resprovince != null && state.rescity
            && state.resbrngy != null && state.altstreet_address != null && state.altprovince != null && state.altcity != null
            && state.altbrngy != null) {
                this.previous_step_error = false;
                this.previous_step = content;
          }else{
            this.previous_step_error = true;
            this.previous_step = content;
            ref.secinfo.checkFields();
          }
        }
        if(content == 'faminfo') {
            this.$refs.faminfo.pushBeneficiaries();
            this.$refs.otherInfo.showDependentId();

            let no_error = true;
              for(let i = 0; i <state.beneficiaries.length;i++){
                  console.log(state.beneficiaries[i]);
                  if((state.beneficiaries[i].beneficiary_name!= '' && state.beneficiaries[i].beneficiary_name!= null && state.beneficiaries[i].beneficiary_name!= undefined) || state.beneficiaries[i].beneficiary_relationship != null && state.beneficiaries[i].beneficiary_relationship != undefined && state.beneficiaries[i].beneficiary_relationship!=''){
                   no_error = true;
                  }else{
                  no_error=false;
                }
              }


            if(state.civil_status == 'mr') {
              this.$refs.faminfo.pushSpouse();
                if(no_error && state.mofirst_name != null &&
                 state.molast_name != null && state.momiddle_name != null && this.familyError==false)
                 {
                  if(state.spouse.length == 1) {
                     if(state.spfirst_name != null && state.splast_name !=null && state.spmiddle_name !=null)
                     {
                        this.previous_step_error = false;
                        this.previous_step = content;
                     }else{
                        this.previous_step_error = true;
                        this.previous_step = content;
                        ref.faminfo.checkFields();
                     }
                  } else if( state.spouse.length == 2){
                     if(no_error && state.spfirst_name != null && state.splast_name !=null && state.spmiddle_name !=null &&
                         state.spfirst_name2 != null && state.splast_name2 !=null && state.spmiddle_name2 !=null)
                     {
                        this.previous_step_error = false;
                        this.previous_step = content;
                     }else{
                        this.previous_step_error = true;
                        this.previous_step = content;
                        ref.faminfo.checkFields();
                     }
                  } else if( state.spouse.length == 3) {
                     if(no_error && state.spfirst_name != null && state.splast_name !=null && state.spmiddle_name !=null &&
                         state.spfirst_name2 != null && state.splast_name2 !=null && state.spmiddle_name2 !=null &&
                         state.spfirst_name3 != null && state.splast_name3 !=null && state.spmiddle_name3 !=null)
                     {
                          this.previous_step_error = false;
                          this.previous_step = content;
                     }else{
                        this.previous_step_error = true;
                        this.previous_step = content;
                        ref.faminfo.checkFields();
                     }
                  } else if( state.spouse.length == 4) {
                     if(no_error && state.spfirst_name != null && state.splast_name !=null && state.spmiddle_name !=null &&
                         state.spfirst_name2 != null && state.splast_name2 !=null && state.spmiddle_name2 !=null &&
                         state.spfirst_name3 != null && state.splast_name3 !=null && state.spmiddle_name3 !=null && 
                         state.spfirst_name4 != null && state.splast_name4 !=null && state.spmiddle_name4 !=null)
                     {
                          this.previous_step_error = false;
                          this.previous_step = content;
                     }else{
                        this.previous_step_error = true;
                        this.previous_step = content;
                        ref.faminfo.checkFields();
                     }
                  }
                }else{
                  this.previous_step_error = true;
                  this.previous_step = content;
                  ref.faminfo.checkBeneEmpty();
                  ref.faminfo.checkFields();
                }
            }else{
               if(no_error && (state.mofirst_name != null && state.mofirst_name!="" && state.mofirst_name!=undefined) &&
                 (state.molast_name != null && state.molast_name!="" && state.molast_name!=undefined) && (state.momiddle_name != null && state.momiddle_name!=""&& state.momiddle_name!=undefined)) {
                        this.previous_step_error = false;
                        this.previous_step = content;
                }else{
                  this.previous_step_error = true;
                  this.previous_step = content;
                  ref.faminfo.checkBeneEmpty();
                  ref.faminfo.checkFields();
                }
            }
            
        }
         
        if(content == 'otherinfo') {
            if(state.branch_of_service == "DEP"){
                  if(state.interview_date != null && state.schedule != null && state.pso != null  && state.front != null && state.sponsor_id != null  && 
                    state.back != null && state.snap != null && state.signature !=null && state.cisa_agree && state.interview_mode != null ) {
                          this.previous_step_error = false;
                          this.previous_step = content;
                    }else{
                      this.previous_step_error = true;
                      this.previous_step = content;
                      ref.otherInfo.checkFields();
                      ref.otherInfo.checkEmpty();
                    }
            }else{
                if(state.interview_date != null && state.schedule != null && state.pso != null  && state.front != null  && 
                  state.back != null && state.snap != null && state.signature !=null && state.cisa_agree && state.interview_mode != null ) {
                        this.previous_step_error = false;
                        this.previous_step = content;
                  }else{
                    this.previous_step_error = true;
                    this.previous_step = content;
                    ref.otherInfo.checkFields();
                    ref.otherInfo.checkEmpty();
                }
            }
        
        }
    },
    submit() {
        let that = this;
        let store = that.$store;
        let state = store.state.membership_details;
        that.loading = true;
            let payload = {
                ref_num:state.ref_account,
                ref_name:state.ref_name,
                br_code:state.pso,
                preferred_pso:state.pso,
                first_name:state.first_name,
                last_name:state.last_name,
                middle_name:state.middle_name,
                gender:state.gender,
                date_of_birth:state.birth_date,
                civil_status:state.civil_status,
                serial_no:state.afp_serial_number!=null && state.afp_serial_number != ''?state.afp_serial_number:"??????",
                tin:state.tin,
                mobile:state.mobile,
                email:state.email,
                signature:state.signature,
                id_front:state.front,
                id_back:state.back,
                father_fn:state.fafirst_name,
                father_ln:state.falast_name,
                father_mn:state.famiddle_name,
                mother_fn:state.mofirst_name,
                mother_ln:state.molast_name,
                mother_mn:state.momiddle_name,
                spouse_fn:state.spfirst_name,
                spouse_ln:state.splast_name,
                spouse_mn:state.spmiddle_name,
                address:state.resstreet_address,
                province:state.resprovince,
                city:state.rescity,
                zipcode:state.reszip_code,
                alt_address:state.altstreet_address,
                alt_province:state.altprovince,
                alt_city:state.altcity,
                alt_zipcode:state.altzip_code,
                empstatus:state.employee_status,
                suffix:state.suffix,
                brgy:state.resbrngy,
                alt_brgy:state.altbrngy,
                birth_place: state.place_of_birth,
                citizenship:state.citizenship,
                branch_service:state.branch_of_service,
                sss_no:state.sss_number,
                gsis_no:state.gsis_number,
                beneficiary:state.beneficiaries,
                schedule:state.schedule,
                share:state.sc,
                sc:state.sc_agree,
                wcp:state.wcp_agree,
                cisa:state.cisa_agree,
                snap:state.snap,
                spouse:state.spouse,
                pmes_dt:moment(Date.now()).format('YYYY-MM-DD hh:mm:ss'),
                rank:state.rank,
                sponsor_nr:state.sponsor_nr,
                sponsor_name:state.sponsor_name,
                sponsor_id:state.sponsor_id,
                interview_mode: state.interview_mode,
            }
          Member.validateApplication(payload).then(function(){
              // that.pmesVid();
              that.loading = false;
              that.show_aggreement = true;
          }).catch(function(error){
                that.error = true;
                that.success = false;
                that.loading = false;
                that.show_message_modal = true;
                that.modal_message = error.response.data.message;
          });

      },
      proceedPmes(){
        this.show_aggreement = false;
        this.pmesVid();
      },
      closepmes(){
        this.show_pmes_video= false;
        this.loading = false;
      },
    nextStep(){
        let current_step='';
        let that = this;
        let store = that.$store;
        let state = store.state.membership_details;
        
        this.checkSteps(this.steps[this.current].content);
      

        if(!this.completed){
            this.steps[this.current].active;
            current_step=this.steps[this.current].content;
        }
        if(current_step=='bsinfo') {
          this.basic_info=true;
          this.address=false;
          this.otherInfo=false;
          this.famInfo=false;
        }
        if(current_step=='secinfo') {
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.famInfo=false;
          this.step_one_done=true;
          
        }
        if(current_step=='faminfo') {
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.step_two_done=true;
        }
        if(current_step=='otherinfo') {
          this.basic_info=false;
          this.address=false;
          this.famInfo=false;
          this.otherInfo=true;
          this.step_three_done=true;
          this.$refs.otherInfo.check();
          this.$refs.otherInfo.pushBranchRef();
          if(state.civil_status =='mr'){
            this.$refs.faminfo.pushSpouse();
          }
          
        }
        if(current_step=='summary') {
          this.$refs.summary.applyChanges();
          this.step_four_done=true;
          this.completed=true;
          this.basic_info=false;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
          this.step_four_done = true;
          
        }
      window.scrollTo(0, 0);
    },
    numberChange(){
      this.valid_number = false;
    },
    emailchange(){
      this.valid_email = false;
    },
    tinchange(){
      this.valid_tin = false;
    },

    checkSteps(content){
        let ref = this.$refs;
        let store = this.$store;
        let state = store.state.membership_details;
        // let reference = store.state.references.ranks[state.branch_of_service];
        if(content == 'bsinfo') {
        //  console.log(state.birth_date);
          var selectedDate = new Date(state.birth_date);
          var currentDate = new Date(Date.now());
          var diff = currentDate.getFullYear() - selectedDate.getFullYear();
          if(this.valid_email == false){
            this.validate_dups('email');
          }
          if(this.valid_number == false){
             this.validate_dups('mobile');
          }
          if(this.valid_tin == false && state.branch_of_service != "DEP" ) {
              this.validate_dups('tin');
          }
          if(state.branch_of_service == "DEP") {
             if(state.sss_number.length > 0 && state.gsis_number.length > 0) {
              // && this.valid_tin 
              if(this.valid_number && this.valid_email && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
              && (state.birth_date != null && state.birth_date != '') && (state.place_of_birth != "" && state.place_of_birth != null) && !state.exist
              && (state.gender != null && state.gender != "") && (state.sponsor_name != '' || state.sponsor_name != null) && (state.sponsor_nr != '' || state.sponsor_nr != null) &&
              (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
              && state.sss_number.length == 9 && state.gsis_number.length == 8) {
                if(this.current<this.steps.length) {
                  ref.bInfo.checkLength();
                  this.current++;
                  this.previous_step= content;
                  
                }
              }else{
                ref.bInfo.checkFields();
                ref.bInfo.checkLength();
                
              }
          }
          else if(state.gsis_number.length > 0) {
            // && this.valid_tin
            if(this.valid_number && this.valid_email  && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null  &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.gsis_number.length == 8) {
              if(this.current<this.steps.length) {
                ref.bInfo.checkLengthGSIS();
                this.current++;
                this.previous_step= content;
                 
              }
            }else{
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthGSIS();
            }
          } 
          else if(state.sss_number.length > 0){
            // && this.valid_tin
            if(this.valid_number && this.valid_email  &&  (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9) {
              if(this.current<this.steps.length) {
                ref.bInfo.checkLengthSSS();
                this.current++;
                this.previous_step= content;
                
              }
            }else{
              ref.bInfo.checkFields();
              ref.bInfo.checkLengthSSS();
            }
          }
          if(state.sss_number.length == 0 && state.gsis_number.length == 0){
            // && this.valid_tin
            if(this.valid_number && this.valid_email  && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && state.sponsor_name != null && state.sponsor_nr != null && state.sponsor_name != '' && state.sponsor_nr != '' && state.gender != null &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email)) {
              if(this.current<this.steps.length) {
                this.current++;
                this.previous_step= content;
              }
            }else{
              ref.bInfo.checkFields();
              
            }
          }

          //else here for DEP IF
          } else {
            
            if(state.sss_number.length > 0 && state.gsis_number.length > 0) {
            console.log(state.afp_serial_number);
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth  && (state.minimum_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
           (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9 && state.gsis_number.length == 8) {
              if(state.rank_required){
                if(state.rank != null && state.rank != ''){
                   if(this.current<this.steps.length) {
                      ref.bInfo.checkLength();
                      this.current++;
                      this.previous_step= content;
                      
                    }
                }else{
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                 if(this.current<this.steps.length) {
                  ref.bInfo.checkLength();
                  this.current++;
                  this.previous_step= content;
                  
                }
              }
            }else{
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          }
          else if(state.gsis_number.length > 0) {
            console.log("state.employee_status1",state.employee_status);
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
           (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.gsis_number.length == 8) {
             if(state.rank_required){
                if(state.rank != null && state.rank != ''){
                   if(this.current<this.steps.length) {
                      ref.bInfo.checkLength();
                      this.current++;
                      this.previous_step= content;
                      
                    }
                }else{
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                 if(this.current<this.steps.length) {
                  ref.bInfo.checkLength();
                  this.current++;
                  this.previous_step= content;
                  
                }
              }
            }else{
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          } 
          else if(state.sss_number.length > 0){
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email) 
            && state.sss_number.length == 9) {
             if(state.rank_required){
                if(state.rank != null && state.rank != ''){
                   if(this.current<this.steps.length) {
                      ref.bInfo.checkLength();
                      this.current++;
                      this.previous_step= content;
                      
                    }
                }else{
                  ref.bInfo.checkFields();
                  ref.bInfo.checkLength();
                }
              }else{
                 if(this.current<this.steps.length) {
                  ref.bInfo.checkLength();
                  this.current++;
                  this.previous_step= content;
                  
                }
              }
            }else{
              ref.bInfo.checkFields();
              ref.bInfo.checkLength();
              
            }
          }
         else if(state.sss_number.length == 0 && state.gsis_number.length == 0){
            if(this.valid_number && this.valid_email && this.valid_tin && (state.first_name != null && state.first_name != '') && (state.last_name != null && state.last_name != '')
            && (state.birth_date != null && state.birth_date != '') && state.place_of_birth && (state.minimum_length == state.afp_serial_number.length || state.max_length == state.afp_serial_number.length)
            && state.gender != null && state.tin != null && state.tin != '' && (state.employee_status!=null && state.employee_status!='') && (state.branch_of_service != null && state.branch_of_service != '') &&
            (state.civil_status !=null && state.civil_status !='') && state.mobile != null && state.email != null  && state.afp_serial_number && diff > 18 && /^(9)\d{9}$/.test(state.mobile.replace('+63', '')) && /^\w+([\\.-]?\w+)*@\w+([\\.-]\w+)*(\.\w{2,3})+$/.test(state.email)) {
             if(state.rank_required){
                if(state.rank != null && state.rank != ''){
                   if(this.current<this.steps.length) {
                      // ref.bInfo.checkLength();
                      this.current++;
                      this.previous_step= content;
                      
                    }
                }else{
                  ref.bInfo.checkFields();
                  // ref.bInfo.checkLength();
                }
              }else{
                 if(this.current<this.steps.length) {
                  // ref.bInfo.checkLength();
                  this.current++;
                  this.previous_step= content;
                  
                }
              }
            }else{
              ref.bInfo.checkFields();
              // ref.bInfo.checkLength();
              
            }
          }
          }
          
        }
        if(content == 'secinfo') {
           if(state.resstreet_address != null && state.resprovince != null && state.rescity
            && state.resbrngy != null && state.altstreet_address != null && state.altprovince != null && state.altcity != null
            && state.altbrngy != null) {
              if(this.current<this.steps.length) {
                this.current++;
                this.previous_step= content;
              }
          }else{
            ref.secinfo.checkFields();
          }
        }
        if(content == 'faminfo') {
            this.$refs.faminfo.pushBeneficiaries();
            this.$refs.otherInfo.showDependentId();

            
            let no_error = true;
              for(let i = 0; i <state.beneficiaries.length;i++){
                  console.log(state.beneficiaries[i]);
                  if((state.beneficiaries[i].beneficiary_name!= '' && state.beneficiaries[i].beneficiary_name!= null && state.beneficiaries[i].beneficiary_name!= undefined) && (state.beneficiaries[i].beneficiary_relationship != null && state.beneficiaries[i].beneficiary_relationship != undefined && state.beneficiaries[i].beneficiary_relationship!='')){
                    no_error = true;
                  } else {
                    no_error=false;
                    break;
                  }
              }


            if(state.civil_status == 'mr') {
              this.$refs.faminfo.pushSpouse();
                if(no_error && state.mofirst_name != null &&
                 state.molast_name != null )
                 {
                  if(state.spouse.length == 1) {
                     if(no_error && state.spfirst_name != null && state.splast_name !=null )
                     {
                        if(this.current<this.steps.length) {
                          this.current++;
                          this.previous_step= content;
                        }
                     }else{
                        ref.faminfo.checkFields();
                     }
                  } else if( state.spouse.length == 2){
                     if(no_error && state.spfirst_name != null && state.splast_name !=null  &&
                         state.spfirst_name2 != null && state.splast_name2 !=null )
                     {
                        if(this.current<this.steps.length) {
                          this.current++;
                          this.previous_step= content;
                        }
                     }else{
                        ref.faminfo.checkFields();
                     }
                  } else if( state.spouse.length == 3) {
                     if(no_error && state.spfirst_name != null && state.splast_name !=null &&
                         state.spfirst_name2 != null && state.splast_name2 !=null &&
                         state.spfirst_name3 != null && state.splast_name3 !=null )
                     {
                        if(this.current<this.steps.length) {
                          this.current++;
                          this.previous_step= content;
                        }
                     }else{
                        ref.faminfo.checkFields();
                     }
                  } else if(no_error && state.spouse.length == 4) {
                     if( state.spfirst_name != null && state.splast_name !=null &&
                         state.spfirst_name2 != null && state.splast_name2 !=null  &&
                         state.spfirst_name3 != null && state.splast_name3 !=null && 
                         state.spfirst_name4 != null && state.splast_name4 !=null )
                     {
                        if(this.current<this.steps.length) {
                          this.current++;
                          this.previous_step= content;
                        }
                     }else{
                        ref.faminfo.checkFields();
                     }
                  }
                }else{
                  ref.faminfo.checkBeneEmpty();
                  ref.faminfo.checkFields();
                }
            }else{
                 if(no_error && (state.mofirst_name != null && state.mofirst_name!="") &&
                 (state.molast_name != null && state.molast_name!="" )) {
                        if(this.current<this.steps.length) {
                          this.current++;
                          this.previous_step_error = false;
                          this.previous_step = content;
                        }
                        
                }else{
                  this.previous_step_error = true;
                  this.previous_step = content;
                  ref.faminfo.checkBeneEmpty();
                  ref.faminfo.checkFields();
                }
            }
            
        }
        if(content == 'otherinfo') {
            if(state.sc_agree){
              if(state.interview_date != null && state.schedule != null && state.pso != null  && state.front != null && state.sc >= 500 &&
              state.back != null && state.snap != null && state.signature !=null && state.cisa_agree && state.interview_mode != null ) {
                if(this.current<this.steps.length) {
                    this.current++;
                    this.previous_step= content;
                  }
              }else{
                ref.otherInfo.checkFields();
                ref.otherInfo.checkEmpty();
              }
            }else{
              if(state.interview_date != null && state.schedule != null && state.pso != null  && state.front != null &&
              state.back != null && state.snap != null && state.signature !=null && state.cisa_agree && state.interview_mode != null ) {
                if(this.current<this.steps.length) {
                    this.current++;
                    this.previous_step= content;
                  }
              }else{
                ref.otherInfo.checkFields();
                ref.otherInfo.checkEmpty();
            }
            }

        }
    },
    validate_dups(id) {
          let that = this;
          let store = that.$store;
          let state = store.state.membership_details;
          let payload;
          that.loading = true;
          if(id == 'tin' && state.tin) {
              payload = {
              type:5,
              value:state.tin,
            }
          }
          if(id =='mobile' && state.mobile) {
            payload = {
              type:3,
              value:state.mobile,
            }
          }
          if(id=='email' && state.email) {
              payload = {
              type:2,
              value:state.email,
            }
          }
          User.testData(payload).then(function(){
            window.scrollTo(0, top);
            that.loading = false;
            if(id=='mobile') {
              that.valid_number = true;
              that.checkvalids();
            }
            if(id=='email') {
              that.valid_email = true;
              that.checkvalids();
            }
            if(id=='tin') {
              that.valid_tin =true;
              that.checkvalids();
            }

          }).catch(function() {
            that.$emit('scrolltop');
            window.scrollTo(0, top);
            that.loading = false;
            if(id=='mobile') {
              that.valid_number = false;
              that.checkvalids();
            }
            if(id=='email') {
              that.valid_email = false;
              that.checkvalids();
            }
            if(id=='tin') {
              that.valid_tin =false;
              that.checkvalids();
            }
          });

    },
    checkvalids() {
      let that = this;
      let store = that.$store;
      let state = store.state.membership_details;

      if(state.branch_of_service == "DEP"){
          if(that.valid_email && that.valid_number) {
            that.nextStep();
          }
      }else{
          if(that.valid_email && that.valid_number && that.valid_tin) {
            that.nextStep();
          }
      }
    
    },
    backStep(){
      let current_step='';

        if(this.current>0){
           this.current--;
        }

        this.steps[this.current].active;
        current_step=this.steps[this.current].content;
        this.completed = false;
        if(current_step=='bsinfo') {
          this.previous_step= current_step;
          this.basic_info=true;
          this.address=false;
          this.otherInfo=false;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='secinfo') {
          this.previous_step= current_step;
          this.basic_info=false;
          this.address=true;
          this.otherInfo=false;
          this.step_one_done=true;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='otherinfo'){
          this.previous_step= current_step;
          this.basic_info=false;
          this.address=false;
          this.otherInfo=true;
          this.step_two_done=true;
          this.summary =false;
          this.famInfo=false;
        }
        if(current_step=='faminfo') {
          this.previous_step= current_step;
          this.basic_info=false;
          this.address=false;
          this.famInfo=true;
          this.otherInfo=false;
          this.step_two_done=true;
          this.summary =false;
        }
        if(current_step=='summary') {
          this.previous_step= current_step;
          this.basic_info=false;
          this.famInfo=false;
          this.address=false;
          this.otherInfo=false;
          this.summary=true;
        }
        window.scrollTo(0, 0);
    }
  },
  watch:{
    basic_info(n){
            setTimeout(() => {
                this.show_step_one = n;
        }, 10);
    },
    address(n){
            setTimeout(() => {
                this.show_step_two = n;
        }, 10);
    },
    famInfo(n){
          setTimeout(() => {
                this.show_step_three = n;
        }, 10);
    },
    otherInfo(n){
          setTimeout(() => {
                this.show_step_four = n;
        }, 10);
    },
    summary(n){
          setTimeout(() => {
                this.show_summary = n;
        }, 10);
    },
  }

})
</script>

<style>
.max-height-container {
  max-height: 80% !important;
}
.ant-steps-item-title{
  font-size: 20px !important;
}
.bg-translucent {
    background-color: rgba(0 ,0 ,0 ,0.75);
}
</style>
