import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ant from 'ant-design-vue';
import cookie from 'vue-cookies';
import "./assets/tailwind.css";
import VueTheMask from 'vue-the-mask'
import vSelect from "vue-select";
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueSignaturePad from 'vue-signature-pad';
import Camera from "simple-vue-camera";
createApp(App).component("camera", Camera).use(store).use(cookie).use(router).use(ant).use(VueSignaturePad).component("v-select", vSelect).use(VueTheMask).use(VueDOMPurifyHTML, {
    default: {
      ADD_TAGS: ['iframe'], // this one whitelists Youtube
    },
  }).mount("#app");
