import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Locale from "@/locale";
import ErrorView from "@/views/ErrorView.vue";
import MemberApplicationView from "@/views/membership/MembersApplicationView.vue";
import MembersOtpView from "@/views/membership/MembersOtpView.vue";
import MembersLandingView from "@/views/membership/MembersLandingView.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MembersLanding",
    meta: {
      title: "Membership Application",
      auth: false,
      passive: false,
    },
    component: MembersLandingView,
  },
  {
    path: "/application",
    name: "MemberShip",
    meta: {
      title: "Membership Application",
      auth: false,
      passive: false,
    },
    component: MemberApplicationView,
  },  
  {
    path: "/member/otp",
    name: "MembershipAddress",
    meta: {
      title: "OTP",
      auth: false,
      passive: false,
    },
    component: MembersOtpView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    meta: {
      title: Locale.get('page.not.found'),
      auth: false,
      passive: true,
    },
    component: ErrorView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to) => {
  const title = to.meta.title as any;
  document.title = title?.toString();
});

export default router;
