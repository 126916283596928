<template>
  <Container :back_ground_color="'items-center h-fit max-w-4xl'">
      <Card max_width="max-w-4xl" :title="'ADDRESS'" :service_type="membership_type">
        <div class="w-full flex flex-col p-4 space-y-2">
        <Container class="h-fit space-y-2" back_ground_color="transparent">
            <span class="text-lg font-bold " v-text="lang('permanent.address')"/> 
            <TextField :max_length="49" uppercase nopaste nospecial required id="Rstreet" ref="Rstreet" type='text' :placeholder="lang('street.address')" @changed="update"/>
            <Container class="h-fit space-x-2 xs:space-x-0 xs:space-y-2" flex_direction='flex-row xs:flex-col' back_ground_color="transparent" >
              <DropDown required id="RProvince" ref="RProvince" taggable :items="province" :placeholder="lang('province')" type='text' @clicked="getProvince" @changed="update"/>
              <TextField :max_length="3" nopaste nospecial id="Rzip" ref="Rzip" type='text' :placeholder="lang('zip.code')" class="max-w-sm xs:max-w-full" @changed="update"/>
            </Container>
            <DropDown :disabled="Rcity_disabled" required id="RCity" ref="RCity" taggable :items="city" :placeholder="lang('city')" type='text' @clicked="getCity" @changed="update"/>
            <DropDown :disabled="Rbarangay_disabled" required id="Rbrngy"  ref="Rbrngy" taggable :items="barangay" type='text' :placeholder="lang('barangay')" @clicked="getBarangay" @changed="update"/>
         </Container>
          <Container class="h-fit space-y-2 " back_ground_color="transparent">
              <div class="flex flex-row xs:flex-col ">
                <span class="text-lg font-bold pt-2" v-text="lang('secondary.address')"/> 
                  <div class="flex flex-row">
                    <Checkbox id="agree_ck" class="text-left pt-2 pl-3 xs:pl-0" :checkbox_width="25" @changed="sameAddress"/>
                  <div class="flex flex-row  mt-auto mb-auto text-lg font-bold pt-1 ">
                      <span v-text="'Same as Above'"/>
                  </div>
                </div>
              </div>  
              <TextField :max_length="49" :disable="same" uppercase nopaste nospecial required id="Sstreet" ref="Sstreet" type='text' :placeholder="same?Rstreet:lang('street.address')" @changed="update"/>
              <Container class="h-fit space-x-2 xs:space-x-0 xs:space-y-2" flex_direction='flex-row xs:flex-col' back_ground_color="transparent" >
                <DropDown :disabled="same" required id="Sprovince" ref="Sprovince" taggable :items="province" :placeholder="same?RProvince:lang('province')" type='text' @clicked="getProvince" @changed="update"/>
                <TextField :disable="same" :max_length="3" nopaste nospecial id="Szip" ref="Szip" type='text' :placeholder="same?Rzip:lang('zip.code')" class="max-w-sm xs:max-w-full" @changed="update"/>
              </Container>
              <DropDown :disabled="Scity_disabled || same" required id="Scity" ref="Scity" taggable :items="city" :placeholder="same?RCity:lang('city')" type='text' @clicked="getCity" @changed="update"/>
              <DropDown :disabled="Sbarangay_disabled || same" required id="Sbrngy" ref="Sbrngy" taggable :items="barangay" type='text' :placeholder="same?Rbrngy:lang('barangay')" @clicked="getBarangay" @changed="update"/>
         </Container>
       </div> 
      </Card>
  </Container>  
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
import DropDown from '@/components/controls/DropDownControl.vue';
import Address from '@/services/Address';
import Checkbox from '@/components/controls/CheckBox.vue';
import mixin from "@/mixin";

export default defineComponent({
  components:{
    Container,
    Card,
    TextField,
    DropDown,
    Checkbox
  },
  props:{
    id:{
      type:String,
      required:true,
    }
  },
  data(){
    return {
      barangay:[],
      province:[],
      province_checker:[],
      city_checker:[],
      city:[],
      same:false,
      Rstreet : null,
      Rbrngy : null,
      Rzip : null,
      RProvince : null,
      RCity : null,
      Sstreet : null,
      Sbrngy : null,
      Szip : null,
      Sprovince : null,
      Scity : null,
      province_id :null,
      city_id :null,
      Rcity_disabled:true,
      Rbarangay_disabled:true,
      Scity_disabled :true,
      Sbarangay_disabled:true,
      membership_type:'',
    }
  },
  mounted(){
     let  that = this;
     let store = that.$store.state;
     let state = store.membership_details;
     that.membership_type = state.mem_type;
    this.getProvince();
    window.scrollTo(0,0);
  },
  methods:{
    checkFields() {
      let ref = this.$refs;
        ref.Rstreet.checkEmpty();
        ref.Rbrngy.checkEmpty();
        ref.Rzip.checkEmpty();
        ref.RProvince.checkEmpty();
        ref.RCity.checkEmpty();
      if(this.same == false){
        ref.Sstreet.checkEmpty();
        ref.Sbrngy.checkEmpty();
        ref.Szip.checkEmpty();
        ref.Sprovince.checkEmpty();
        ref.Scity.checkEmpty();
      }
    },
    getBarangay(){
      let that = this;
      that.barangay=[];
        // console.log(that.province_id);
      if(that.province_id =='130000000'){
        if(that.barangay.length <= 0){
          Address.getBarangay(that.city_id).then(function(response){
            let barangay_data = response.data;
            for(let index = 0 ; index < barangay_data.length ; index++) {
              // console.log(barangay_data[index].cityCode);
              if(that.city_id == barangay_data[index].cityCode){
                  that.barangay.push(barangay_data[index].name.toUpperCase());
              }
            }
          });
        }
      } else {
        if(that.barangay.length <= 0){
          Address.getBarangay(that.city_id).then(function(response){
            let barangay_data = response.data;
            for(let index = 0 ; index < barangay_data.length ; index++) {
                that.barangay.push(barangay_data[index].name.toUpperCase());
            }
          });
        }
      }
    },
    getProvince() {
      let that = this;
      if(that.province.length <= 0){
       Address.getProvince().then(function(response){
        let province_data = response.data;
        let ncr = {
          code: "130000000",
          islandGroupCode: "luzon",
          name: "METRO MANILA",
          psgc10DigitCode: "1300000000"
        }
        province_data.push(ncr);
        that.province_checker = province_data;
          for(let index = 0 ; index < province_data.length ; index++) {
            that.province.push(province_data[index].name.toUpperCase());
          }
       });
      }
    },
    getCity(){
       let that = this;
       that.city=[];
        // console.log(that.province_id);
       if(that.province_id =='130000000'){
        if(that.city.length <= 0){
          Address.getNcrCities().then(function(response){
            let city_data = response.data;
            that.city_checker= city_data;
            for(let index = 0 ; index < city_data.length ; index++) {
              // console.log(city_data[index].name);
                that.city.push(city_data[index].name.toUpperCase());
            }
             that.Rbarangay_disabled = false;
              that.Sbarangay_disabled = false;
          });
        }
      } else{
        if(that.city.length <= 0) {
          // console.log(that.province_id);
          Address.getCity(that.province_id).then(function(response){
            let city_data = response.data;
            that.city_checker= city_data;
              for(let index = 0 ; index < city_data.length ; index++) {
                  that.city.push(city_data[index].name.toUpperCase());
              }
              that.Rbarangay_disabled = false;
              that.Sbarangay_disabled = false;
          });
        }
      }
        
    },
    sameAddress(e){
      let that = this;
      let store = that.$store;
      let state = store.state;
      that.same = e;

        that.$refs.Sstreet.clearText();
        that.$refs.Szip.clearText();
        that.$refs.Sbrngy.clearValue();
        that.$refs.Scity.clearValue();
        that.$refs.Sprovince.clearValue();
        
      if(that.same) {
          state.membership_details.altstreet_address = that.Rstreet;
          state.membership_details.altzip_code = that.Rzip;
          state.membership_details.altprovince = that.RProvince;
          state.membership_details.altcity = that.RCity;
          state.membership_details.altbrngy = that.Rbrngy;
        } else {
          state.membership_details.altstreet_address = null;
          state.membership_details.altzip_code = null;
          state.membership_details.altprovince = null;
          state.membership_details.altcity = null;
          state.membership_details.altbrngy = null;
        }
      
    },
    update(e) {
          let key = e.key;
          let that = this;
          let store = that.$store;
          let state = store.state;

          that.error_message = false;
          that[key] = e.value;
          
          state.membership_details.resstreet_address = that.Rstreet;
          state.membership_details.reszip_code = that.Rzip;
          state.membership_details.resprovince = that.RProvince;
          state.membership_details.rescity = that.RCity;
          state.membership_details.resbrngy = that.Rbrngy;

          if(this.same == false) {
            state.membership_details.altstreet_address = that.Sstreet;
            state.membership_details.altzip_code = that.Szip;
            state.membership_details.altprovince = that.Sprovince;
            state.membership_details.altcity = that.Scity;
            state.membership_details.altbrngy = that.Sbrngy;
          } else {
            state.membership_details.altstreet_address = that.Rstreet;
            state.membership_details.altzip_code = that.Rzip;
            state.membership_details.altprovince = that.RProvince;
            state.membership_details.altcity = that.RCity;
            state.membership_details.altbrngy = that.Rbrngy;
          }
        
         

          // this.enableButton();
        },
  },
  watch:{
    RProvince(e) {
      let that = this;
      that.Rcity_disabled = false;
      that.Scity_disabled = false;
      // console.log(e);
        for(let provInd = 0; provInd < that.province_checker.length;provInd++){
          // console.log(that.province_checker[provInd].name ,e);
          if(that.province_checker[provInd].name.toUpperCase() == e) {
            that.province_id = that.province_checker[provInd].code;
            // console.log(that.province_id);
          }
          
        }
    },
    RCity(e){
      let that = this;
      // console.log(e);
      for(let provInd = 0; provInd < that.city_checker.length;provInd++){
        if(that.city_checker[provInd].name.toUpperCase() == e) {
          that.city_id = that.city_checker[provInd].code;
          // console.log(that.city_id);
        }
      }
    },
    Sprovince(e) {
      let that = this;
        for(let provInd = 0; provInd < that.province_checker.length;provInd++){
          if(that.province_checker[provInd].name.toUpperCase() == e) {
            that.province_id = that.province_checker[provInd].code;
          }
        }
    },
    Scity(e){
      let that = this;
      for(let provInd = 0; provInd < that.city_checker.length;provInd++){
        if(that.city_checker[provInd].name.toUpperCase() == e) {
          that.city_id = that.city_checker[provInd].code;
        }
      }
    }
  },
   mixins: [
    mixin,
  ],
})
</script>
<style scoped>

</style>
