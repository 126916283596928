<template>
<div class="relative xs:w-full items-center justify-center flex">
 <Container back_ground_color='items-center max_height relative max-w-4xl' >
      <Card max_width="max-w-7xl p-2 overflow-y-auto max-h-min  " :title="'FAMILY Information'" :service_type="membership_type">
          <div class="w-full flex flex-col p-4 space-y-2">
           <div class="flex text-lg w-full font-bold items-center space-x-2" v-if="this.$store.state.membership_details.civil_status == 'mr'">
              <span v-text="'SPOUSE NAME'"  class="font-bold text-xl  "/>
              <div class="flex-grow"/>
              <Button :button_text="'+'" text_size="text-2xl -mt-1" class="font-bold w-12 " @click="addSpouse"/>
            </div>
            <div class="w-full h-fit" v-if="this.$store.state.membership_details.civil_status == 'mr'">
              <!-- <Button :max_width="' text-4xl font-bold  items-center'" :button_text="'+'" /> -->
              <Container v-for="(items,index) in spouse_list" :key="index" class="h-fit relative" flex_direction='flex-row space-x-1 md:flex-row sm:flex-col xs:flex-col xs:space-x-0 xs:space-y-2' back_ground_color="transparent">
                <TextField uppercase class="pt-1" :ref="'spouse'+index+'_last_name'" :required="this.$store.state.membership_details.civil_status == 'mr'" nonumbers :id="this.spouse_list.length==1?'spouse_last_name':'spouse_last_name'+index" type="text" :placeholder="lang('last.name.textfield')" @changed="update"/>
                <TextField uppercase class="pt-1" :ref="'spouse'+index+'_first_name'" :required="this.$store.state.membership_details.civil_status == 'mr'" nonumbers :id="this.spouse_list.length==1?'spouse_first_name':'spouse_first_name'+index" type="text" :placeholder="lang('first.name.textfield')" @changed="update"/>
                <TextField uppercase class="pt-1" :ref="'spouse'+index+'_middle_name'"  nonumbers :id="this.spouse_list.length==1?'spouse_middle_name':'spouse_middle_name'+index" type="text" :placeholder="lang('middle.name.textfield')" @changed="update"/>
                <Button v-if="this.spouse_list.length > 1" :max_width="'w-12 text-lg font-bold bg-white text-red-600 self-center flex'" :button_text="'X'" @click="removeSpouse(index)"/>
              </Container>
            </div>
            <div class="flex text-lg font-bold  ">MOTHER'S MAIDEN NAME</div>
            <Container class="h-fit" flex_direction='md:flex-row sm:flex-col xs:flex-col xs:space-x-0 space-x-1 xs:space-y-2' back_ground_color="transparent">
              <TextField required :max_length="49" uppercase nonumbers ref="mother_last_name" :id="'mother_last_name'" type="text" :placeholder="lang('last.name.textfield')" @changed="update"/>
              <TextField required :max_length="49" uppercase nonumbers ref="mother_first_name" :id="'mother_first_name'" type="text" :placeholder="lang('first.name.textfield')" @changed="update"/>
              <TextField  :max_length="49" uppercase nonumbers ref="mother_middle_name" :id="'mother_middle_name'" type="text" :placeholder="lang('middle.name.textfield')" @changed="update"/>
            </Container>
            <div class="flex  text-lg font-bold  ">FATHER'S NAME</div>
            <Container class="h-fit" flex_direction='flex-row space-x-1 md:flex-row sm:flex-col xs:flex-col xs:space-x-0 xs:space-y-2' back_ground_color="transparent">
              <TextField :max_length="49" uppercase nonumbers :id="'father_last_name'" type="text" :placeholder="lang('last.name.textfield')" @changed="update"/>
              <TextField :max_length="49" uppercase nonumbers :id="'father_first_name'" type="text" :placeholder="lang('first.name.textfield')" @changed="update"/>
              <TextField :max_length="49" uppercase nonumbers :id="'father_middle_name'" type="text" :placeholder="lang('middle.name.textfield')" @changed="update"/>
            </Container>
            <div class="flex text-lg w-full font-bold items-center space-x-2">
              <span v-text="'BENEFICIARIES'" />
              <div class="flex-grow"/>
              <Button :button_text="'+'" text_size="text-2xl -mt-1" class="font-bold w-12 " @click="addBeneficiaries"/>
              <!-- <Button :button_text="'Push'" text_size="text-2xl -mt-1" class="font-bold w-12 " @click="pushBeneficiaries"/> -->
            </div>
            <div class="w-full h-fit">
              <!-- <Button :max_width="' text-4xl font-bold  items-center'" :button_text="'+'" /> -->
              <Container v-for="(items,index) in beneficiaries_list" :key="index" class="h-fit relative" flex_direction='flex-row space-x-1 md:flex-row sm:flex-col xs:flex-col xs:space-x-0 xs:space-y-2' back_ground_color="transparent">
                <TextField uppercase required :ref="'beneficiaries'+index+'_name'" :hasError="hasError" nonumbers :id="this.beneficiaries_list.length==1?'beneficiary_name':'beneficiary_name'+index" type="text" :placeholder="lang('Name e.g (Last Name, First Name, M.I)')" @changed="update"/>
                <TextField uppercase required :ref="'beneficiaries'+index+'_relationship'" :hasError="hasError" nonumbers :id="this.beneficiaries_list.length==1?'beneficiary_relationship':'beneficiary_relationship'+index" type="text" :placeholder="lang('Relationship')" @changed="update"/>
                <TextField uppercase  :ref="'beneficiaries'+index+'_address'" nonumbers :hasError="hasError"  :id="this.beneficiaries_list.length==1?'beneficiary_address':'beneficiary_address'+index" type="text" :placeholder="lang('Address')" @changed="update"/>
                <TextField nopaste  :hasError="hasError"  nospecial :max_length="9" :ref="'beneficiaries'+ index +'_mobile' " :id="this.beneficiaries_list.length==1?'beneficiary_mobile':'beneficiary_mobile'+index" type='mobile' :placeholder="lang('Mobile No.')" @changed="update"/>
                <Button v-if="this.beneficiaries_list.length > 1" :max_width="'w-12 text-lg font-bold bg-white text-red-600 self-center flex'" :button_text="'X'" @click="removeBeneficiaries(index)"/>
              </Container>
            </div>
          </div>
      </Card>
  </Container>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Container from '@/components/sections/ContainerView.vue';
import Card from '@/components/controls/CardView.vue';
import TextField from '@/components/controls/TextBoxField.vue';
// import UploadFile from '@/components/controls/UploadControl.vue';
import Button from '@/components/controls/ButtonControl.vue';
import mixin from "@/mixin";

export default defineComponent({
  components: {
    Container,
    Card,
    TextField,
    Button,
  },
  props:{
    id:{
      type:String,
      required:true,
    }
  },
  data(){
    return {
      branches:['PAFCPIC SATELLITE OFFICE - TARLAC','PAFCPIC SATELLITE OFFICE - ISABELA','PAFCPIC SATELLITE OFFICE - MAGSAYSAY','PAFCPIC SATELLITE OFFICE - BICOL','PAFCPIC SATELLITE OFFICE - BUTUAN','PAFCPIC SATELLITE OFFICE - ILOILO','PAFCPIC SATELLITE OFFICE - CEBU','PAFCPIC SATELLITE OFFICE - SAMAR','PAFCPIC SATELLITE OFFICE - PAGADIAN','PAFCPIC SATELLITE OFFICE - CDO','PAFCPIC SATELLITE OFFICE - DABAO','PAFCPIC SATELLITE OFFICE - COTABATO','PAFCPIC SATELLITE OFFICE - ILOCOS','PAFCPIC SATELLITE OFFICE - GHQ','PAFCPIC SATELLITE OFFICE - ZAMBOANGA','PAFCPIC SATELLITE OFFICE - TANAY','PAFCPIC SATELLITE OFFICE - LUCENA','PAFCPIC SATELLITE OFFICE - GENSAN','PAFCPIC SATELLITE OFFICE - JAMINDAN','PAFCPIC SATELLITE OFFICE - CAPAS','PAFCPIC SATELLITE OFFICE - NEGROS','PAFCPIC SATELLITE OFFICE - LEGAZPI','PAFCPIC SATELLITE OFFICE - PALAWAN'],
      spouse_first_name: null,
      spouse_last_name: null,
      spouse_middle_name: null,
      spouse_first_name1: null,
      spouse_last_name1: null,
      spouse_middle_name1: null,
      spouse_first_name2: null,
      spouse_last_name2: null,
      spouse_middle_name2: null,
      spouse_first_name3: null,
      spouse_last_name3: null,
      spouse_middle_name3: null,
      mother_first_name: null,
      mother_last_name: null,
      mother_middle_name: null,
      father_first_name: null,
      father_last_name: null,
      father_middle_name: null,
      spouse_list:['spouse'],
      beneficiaries_list:['beneficiaries'],
      membership_type:'',
    }
  },
  mounted(){
     let  that = this;
     let store = that.$store.state;
     let state = store.membership_details;
     that.membership_type = state.mem_type;
  },
 mixins: [
    mixin,
  ],
  methods:{
    update(e) {
          let key = e.key;
          let that = this;
          let store = that.$store;
          let state = store.state;

          that.error_message = false;
          that[key] = e.value;
          // console.log(that.spouse_middle_name);

          that.show_selfie_modal =  false;
          that.show_signature_pad = false;
          state.membership_details.spfirst_name = that.spouse_first_name != '' ? that.spouse_first_name :null;
          state.membership_details.splast_name = that.spouse_last_name != '' ? that.spouse_last_name :null;
          state.membership_details.spmiddle_name = that.spouse_middle_name != '' ? that.spouse_middle_name :null;

          state.membership_details.spfirst_name2 = that.spouse_first_name1;
          state.membership_details.splast_name2 = that.spouse_last_name1;
          state.membership_details.spmiddle_name2 = that.spouse_middle_name1;

          state.membership_details.spfirst_name3 = that.spouse_first_name2;
          state.membership_details.splast_name3 = that.spouse_last_name2;
          state.membership_details.spmiddle_name3 = that.spouse_middle_name2;

          state.membership_details.spfirst_name4 = that.spouse_first_name3;
          state.membership_details.splast_name4 = that.spouse_last_name3;
          state.membership_details.spmiddle_name4 = that.spouse_middle_name3;

          state.membership_details.mofirst_name = that.mother_first_name;
          state.membership_details.molast_name = that.mother_last_name;
          state.membership_details.momiddle_name = that.mother_middle_name;
          state.membership_details.fafirst_name = that.father_first_name;
          state.membership_details.falast_name = that.father_last_name;
          state.membership_details.famiddle_name = that.father_middle_name;
          // console.log(state.membership_details);
        },
        addSpouse() {
            let spouse_count = this.spouse_list.length;
            if(spouse_count < 4){
               this.spouse_list.push('spouse'+spouse_count);
            }
        },
        hasError(e){
          if(e)
          {
            this.$emit('hasError',true);
          }
          else{
            this.$emit('clearError',false);
          }
        },
         addBeneficiaries() {
            let beneficiaries = this.beneficiaries_list.length;
            this.beneficiaries_list.push('beneficiaries'+beneficiaries);

        },
        removeSpouse(e){
          this.spouse_list.splice(e,1);
        },
        pushSpouse(){
          let that =this;
          let store = that.$store;
          let state = store.state;
          let payload = {};
          state.membership_details.spouse =[];

          for(let i=0;i<this.spouse_list.length;i++){
              if(i==0){
                payload={
                  first_name:that.$refs[that.spouse_list[i]+i+"_first_name"][0].curr_val == undefined ? null :that.$refs[that.spouse_list[i]+i+"_first_name"][0].curr_val,
                  last_name:that.$refs[that.spouse_list[i]+i+"_last_name"][0].curr_val == undefined ? null :that.$refs[that.spouse_list[i]+i+"_last_name"][0].curr_val,
                  middle_name:that.$refs[that.spouse_list[i]+i+"_middle_name"][0].curr_val == undefined ? null : that.$refs[that.spouse_list[i]+i+"_middle_name"][0].curr_val,
                }
              }else{
                 payload={
                  first_name:that.$refs[that.spouse_list[i]+"_first_name"][0].curr_val == undefined ? null :that.$refs[that.spouse_list[i]+"_first_name"][0].curr_val,
                  last_name:that.$refs[that.spouse_list[i]+"_last_name"][0].curr_val == undefined ? null :that.$refs[that.spouse_list[i]+"_last_name"][0].curr_va,
                  middle_name:that.$refs[that.spouse_list[i]+"_middle_name"][0].curr_val == undefined ? null :that.$refs[that.spouse_list[i]+"_middle_name"][0].curr_val ,
                }
              }
              state.membership_details.spouse.push(payload);
          }
        },
        checkBeneEmpty(){
          let that = this;
          // console.log('asdas');
          for(let i=0;i<that.beneficiaries_list.length;i++){
             if(i==0){
              that.$refs[that.beneficiaries_list[i]+i+"_name"][0].checkEmpty();
              that.$refs[that.beneficiaries_list[i]+i+"_relationship"][0].checkEmpty();
              // that.$refs[that.beneficiaries_list[i]+i+"_address"][0].checkEmpty();
              // that.$refs[that.beneficiaries_list[i]+i+"_mobile"][0].checkEmpty();
             }else{
              that.$refs[that.beneficiaries_list[i]+"_name"][0].checkEmpty();
              that.$refs[that.beneficiaries_list[i]+"_relationship"][0].checkEmpty();
              // that.$refs[that.beneficiaries_list[i]+"_address"][0].checkEmpty();
              // that.$refs[that.beneficiaries_list[i]+"_mobile"][0].checkEmpty();
             }
              
          }
        },
        pushBeneficiaries(){
          let that =this;
          let store = that.$store;
          let state = store.state;
          let payload = {};
          state.membership_details.beneficiaries =[];
          // console.log('entered benefi push');

          that.beneficiaries_list.forEach(function(key,value){
             if(key=="beneficiaries"){
              console.log(that.$refs[key+value+"_name"][0].curr_val);
                payload={
                  beneficiary_name:that.$refs[key+value+"_name"][0].curr_val,
                  beneficiary_relationship:that.$refs[key+value+"_relationship"][0].curr_val,
                  beneficiary_address:that.$refs[key+value+"_address"][0].curr_val == undefined ||that.$refs[key+value+"_address"][0].curr_val == ""?"":that.$refs[key+value+"_address"][0].curr_val,
                  beneficiary_mobile:that.$refs[key+value+"_mobile"][0].curr_val == undefined || that.$refs[key+value+"_mobile"][0].curr_val == "" ?"":"63"+that.$refs[key+value+"_mobile"][0].curr_val,
                }
              }else{
                console.log(key);
                 payload = {
                  beneficiary_name:that.$refs[key+"_name"][0].curr_val,
                  beneficiary_relationship:that.$refs[key+"_relationship"][0].curr_val,
                  beneficiary_address:that.$refs[key+"_address"][0].curr_val==undefined || that.$refs[key+"_address"][0].curr_val==""?"":that.$refs[key+"_address"][0].curr_val,
                  beneficiary_mobile:that.$refs[key+"_mobile"][0].curr_val == undefined || that.$refs[key+"_mobile"][0].curr_val == "" ?"":"63"+that.$refs[key+"_mobile"][0].curr_val,
                }
              }
            state.membership_details.beneficiaries.push(payload);
          
          });

          // for(let i=0;i<that.beneficiaries_list.length;i++){
          //     if(i==0){
          //       payload={
          //         beneficiary_name:that.$refs[that.beneficiaries_list[i]+i+"_name"][0].curr_val,
          //         beneficiary_relationship:that.$refs[that.beneficiaries_list[i]+i+"_relationship"][0].curr_val,
          //         beneficiary_address:that.$refs[that.beneficiaries_list[i]+i+"_address"][0].curr_val == undefined ||that.$refs[that.beneficiaries_list[i]+i+"_address"][0].curr_val == ""?"":that.$refs[that.beneficiaries_list[i]+i+"_address"][0].curr_val,
          //         beneficiary_mobile:that.$refs[that.beneficiaries_list[i]+i+"_mobile"][0].curr_val == undefined || that.$refs[that.beneficiaries_list[i]+i+"_mobile"][0].curr_val == "" ?"":"63"+that.$refs[that.beneficiaries_list[i]+i+"_mobile"][0].curr_val,
          //       }
          //     }else{
          //        payload = {
          //         beneficiary_name:that.$refs[that.beneficiaries_list[i]+"_name"][0].curr_val,
          //         beneficiary_relationship:that.$refs[that.beneficiaries_list[i]+"_relationship"][0].curr_val,
          //         beneficiary_address:that.$refs[that.beneficiaries_list[i]+"_address"][0].curr_val==undefined || that.$refs[that.beneficiaries_list[i]+"_address"][0].curr_val==""?"":that.$refs[that.beneficiaries_list[i]+"_address"][0].curr_val,
          //         beneficiary_mobile:that.$refs[that.beneficiaries_list[i]+"_mobile"][0].curr_val == undefined || that.$refs[that.beneficiaries_list[i]+"_mobile"][0].curr_val == "" ?"":"63"+that.$refs[that.beneficiaries_list[i]+"_mobile"][0].curr_val,
          //       }
          //     }
          //     // console.log(payload);
          //     if((payload.beneficiary_name != undefined && payload.beneficiary_name != "" )  && (payload.beneficiary_relationship != undefined && payload.beneficiary_relationship != "" )){
          //       if(payload.beneficiary_mobile.length<10){
          //         payload.beneficiary_mobile = "";
          //         state.membership_details.beneficiaries.push(payload);
          //       }else{
          //         state.membership_details.beneficiaries.push(payload);
          //       }
                
          //     }
              
          // }
          // console.log(payload);
        },
        removeBeneficiaries(e){
          let that = this;
          that.beneficiaries_list.splice(e,1);
          that.refresh_array();
        },
        refresh_array(){
          let that = this;
          let new_array = [];
          // console.log(old_array);
          
          that.beneficiaries_list.forEach(function(key,value){
            if(value==0){
                //  console.log(new_array);
                  new_array.push("beneficiaries");
              }else{
                //  console.log(new_array);
                  new_array.push("beneficiaries"+value);
              }
          })
          that.beneficiaries_list = new_array;
        },
        getBranchCode(e){
          let that = this;
          let store = that.$store;
          let state = store.state;

          let branches=[{key:'PAFCPIC SATELLITE OFFICE - TARLAC',code:'TA'},{key:'PAFCPIC SATELLITE OFFICE - ISABELA',code:'IS'},{key:'PAFCPIC SATELLITE OFFICE - MAGSAYSAY',code:'MA'},{key:'PAFCPIC SATELLITE OFFICE - BICOL',code:'BI'},{key:'PAFCPIC SATELLITE OFFICE - BUTUAN',code:'BU'},{key:'PAFCPIC SATELLITE OFFICE - ILOILO',code:'IL'},{key:'PAFCPIC SATELLITE OFFICE - CEBU',code:'CE'},{key:'PAFCPIC SATELLITE OFFICE - SAMAR',code:'SA'},{key:'PAFCPIC SATELLITE OFFICE - PAGADIAN',code:'PA'},{key:'PAFCPIC SATELLITE OFFICE - CDO',code:'CA'},{key:'PAFCPIC SATELLITE OFFICE - DABAO',code:'DA'},{key:'PAFCPIC SATELLITE OFFICE - COTABATO',code:'CO'},{key:'PAFCPIC SATELLITE OFFICE - ILOCOS',code:'IR'},{key:'PAFCPIC SATELLITE OFFICE - GHQ',code:'GQ'},{key:'PAFCPIC SATELLITE OFFICE - ZAMBOANGA',code:'ZA'},{key:'PAFCPIC SATELLITE OFFICE - TANAY',code:'RI'},{key:'PAFCPIC SATELLITE OFFICE - LUCENA',code:'LU'},{key:'PAFCPIC SATELLITE OFFICE - GENSAN',code:'GE'},{key:'PAFCPIC SATELLITE OFFICE - JAMINDAN',code:'JA'},{key:'PAFCPIC SATELLITE OFFICE - CAPAS',code:'CP'},{key:'PAFCPIC SATELLITE OFFICE - NEGROS',code:'NE'},{key:'PAFCPIC SATELLITE OFFICE - LEGAZPI',code:'LE'},{key:'PAFCPIC SATELLITE OFFICE - PALAWAN',code:'PW'}]
          for(let index = 0; index < branches.length ; index++) {
            if(branches[index].key == e){
              state.membership_details.pso_text=branches[index].key;
              // console.log(state.membership_details.pso_text);
              return branches[index].code;
            }
          }
        },
        checkFields() {
        let ref = this.$refs;
        let store = this.$store.state.membership_details.civil_status;
        if(store == "mr"){
          for(let i=0;i<this.spouse_list.length;i++){
            
            if(i==0){
              ref[this.spouse_list[i]+i+'_last_name'][0].checkEmpty();
              ref[this.spouse_list[i]+i+'_first_name'][0].checkEmpty();
              ref[this.spouse_list[i]+i+'_middle_name'][0].checkEmpty();
            }else{
              // console.log(this.spouse_list[i]);
              ref[this.spouse_list[i]+'_last_name'][0].checkEmpty();
              ref[this.spouse_list[i]+'_first_name'][0].checkEmpty();
              ref[this.spouse_list[i]+'_middle_name'][0].checkEmpty();
            }
          }
          ref.mother_last_name.checkEmpty();
          ref.mother_first_name.checkEmpty();
          ref.mother_middle_name.checkEmpty();
        }else{
          ref.mother_last_name.checkEmpty();
          ref.mother_first_name.checkEmpty();
          ref.mother_middle_name.checkEmpty();
        }
          
        // ref.Branch.checkEmpty();
        // ref.IntDate.checkEmpty();
        // ref.frontImage.checkEmpty();
        // ref.backImage.checkEmpty();
        // ref.signature.checkEmpty();
      },
  }
})
</script>
<style scoped>
.max_height {
  height: calc(100% - 120px);
}
.max_card {
 max-height: 100% !important;
}
</style>