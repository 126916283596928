<template>
  <ModalControl 
    v-show="show_modal" 
    v-bind:display="show_modal" 
    v-bind:title="lang(title)"
    closable 
    id='Modal'
    :z_value="'z-50'"
    :default_width="default_width" 
    @closed="closed"
    >
      <div class="w-full ">
        <Privacy v-show="show_privacy"/>
        <TermsView v-show="show_terms"/>
        <HelpCenterView v-show="show_contact"/>
        <OurCompany v-show="show_about"/>
      </div>
  </ModalControl>
  <Container flex_direction='flex-row'>
      <!-- <SidebarView 
        v-show="false" 
        :display="this.show_sidebar"
      /> -->
    <Container flex_direction="flex-col h-screen w-full relative space-y-10">  
          <RouterView
                @loadSideBar="loadSideBar"
                @openContact="clicked('Contact Us')"
                @OpenTerms="clicked('Terms')"
                @OpenPrivacy="clicked('Privacy')"
                @OpenAbout="clicked('About Us')"
                @scrollTop="scrollTop"
                class="w-full "
          />
          <FooterView  class="xs:hidden" :copy_right_text="lang('copy.right.text')" :clickables="clickables" @clicked="clicked" /> 
    </Container>
  </Container>
</template>

<script>
import { defineComponent } from "vue";
import mixin from "@/mixin";
import FooterView from "./components/sections/FooterView.vue";
// import NavBarView from "@/components/controls/NavBarView.vue";
// import User from "@/services/User";
// import System from "./services/System";
import ModalControl from "@/components/controls/ModalControl.vue";
import Privacy from "@/components/sections/PrivacyPolicyView.vue";
import TermsView from "@/components/sections/TermsOfUserView.vue";
import OurCompany from "@/views/static/OurCompanyView.vue"
import HelpCenterView from "@/components/sections/ContactUsView.vue";
// import SidebarView from "@/components/controls/SidebarView.vue";
import Container from "@/components/sections/ContainerView.vue";

export default defineComponent({
  name: "App",
  mixins:[
    mixin,
  ],
  components: {
    Container,
    FooterView,
    OurCompany,
    Privacy,
    TermsView,
    ModalControl,
    HelpCenterView,
    // SidebarView,
  },
  data() {
    return {
      show_modal:false,
      show_privacy:false,
      show_terms:false,
      show_contact:false,
      show_about:false,
      logged:false,
      show_bars:false,
      show_sidebar:false,
      title:'',
      default_width:'',
      clickables:['About Us','Contact Us','Privacy','Terms',],
      noframe:'',
      back_path:'',
      screen_title: {
        value:'',
      },
      route: {
          auth:'',
          passive:'',
          path:'',
          navbar:'',
      }
    }
  },
  methods: {
    loadSideBar() {
        this.show_bars = true;
    },
    clicked(n) {
      if(n == 'Privacy') {
        this.show_privacy =true;
        this.show_modal=true;
        this.title = ('privacy.policy.header.text');
        this.default_width = 'max-w-5xl'
      }
      if(n == 'Terms') {
        this.show_terms = true;
        this.show_modal = true;
        this.title = ('terms.header.text');
        this.default_width = 'max-w-5xl'
      }
      if(n == 'Contact Us') {
        this.show_contact = true;
        this.show_modal = true;
        this.title = ('contact.header.text');
        this.default_width = 'max-w-3xl'
      }
      if(n == 'About Us'){
        this.show_about = true;
        this.show_modal = true;
        this.title = ('About Us');
        this.default_width = 'max-w-3xl'
        
      }
    },
    scrollTop(){
      // console.log('scrollTop');
      window.scrollTo(0, 0);
    },
    closed() {
      this.show_privacy = false;
      this.show_contact = false;
      this.show_terms = false;
      this.show_about = false;
      this.show_modal = false;
    }
  },
  watch: {
    $route(to) {
      this.noframe = (to.path == "/");
      this.back_path = '';
      this.screen_title.value = to.meta.title;
      this.route.auth = to.meta.auth;
      this.route.passive = to.meta.passive;
      this.route.path = to.path;
      this.route.navbar = to.meta.navbar;
    },
  },
});
</script>

<style scoped>
.body::-webkit-scrollbar{
  display: none;
}

</style>
