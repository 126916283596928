<template>
    <div class="w-full space-y-2">
        <video ref="video" class="w-full" @canplay="initCanvas()" >Stream unavailable</video>
        <Button :button_text="'CAPTURE'"  @clicked="takePicture"/>
        <img v-if="show" :src="image" alt="avatar" />
        <canvas ref="canvas" style="display:none"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
// import Camera from "simple-vue-camera";
import Button from "@/components/controls/ButtonControl.vue"
export default defineComponent({
    components:{
        Button,
        // Camera
    },
    mounted(){
        this.video =this.$refs.video;
        this.canvas =this.$refs.canvas;
        this.capture();
    },
    props:{
        id:{
            type:String,
            required:true,
        }
    },
    data(){
        return {
            video:null,
            canvas:null,
            show:false,
        }
    },
    methods:{
        capture(){
            navigator.mediaDevices.getUserMedia({video:true,audio:false}).then(stream =>{
                this.video.srcObject = stream;
                this.video.play()
            }).catch(error=>{
                console.error(error)
            })
        },
        takePicture(){
            let context = this.canvas.getContext('2d')
            this.image=null;
            this.show = false;
            context.drawImage(this.video,0,0,this.video.videoWidth,this.video.videoHeight);
            this.image = this.canvas.toDataURL('image/jpeg');
             this.$emit('changed',{
              key: this.id,
              value:this.canvas.toDataURL('image/jpeg'),
            });
             this.show = true;
        },
        initCanvas(){
            this.canvas.setAttribute('width',this.video.videoWidth);
            this.canvas.setAttribute('height',this.video.videoHeight);
        }
    }
})
</script>
