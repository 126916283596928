<template>
    <div class="bg-white flex w-full h-full">
        <img src="@/assets/images/WEB2.jpg" class="fixed h-screen w-full  "/>
        <div class="w-full h-full flex flex-col xs:p-2 p-10 justify-center items-center z-40 space-y-1">
            <Privacy class="w-full border-2 overflow-y-auto xs:h-full h-5/6 max-w-4xl -mt-10 xs:-mt-0 border-gray-300 rounded" v-if="!show_terms"/>
            <TermsView class="w-full border-2 overflow-y-auto xs:h-full h-5/6 max-w-4xl -mt-11 xs:-mt-0  border-gray-300 rounded" v-if="show_terms"/>
            <div class="flex flex-row w-full max-w-4xl " v-if="show_terms">
                <Checkbox  id="agree_ck" class="text-left mt-auto mb-auto" :checkbox_width="32" @changed="update"/>
                <div class="flex flex-wrap mt-auto mb-auto font-bold md:text-lg">
                    <span v-text="'I Agree with the End User License Agreement and Privacy Policy'"/>
                </div>
            </div>
            <div class="w-full flex -pt-1 max-w-4xl">
                <Button text_size="text-2xl xs:text-lg" height="h-14 xs:h-10" :button_text="button_text" class="" @clicked="showTerms" v-if="!show_terms" />
                <Button text_size="text-2xl xs:text-lg" height="h-14 xs:h-10" :button_text="button_text" class="" @clicked="proceed" v-if="show_terms" :disabled="!aggree && show_terms"/>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import Privacy from "@/components/sections/PrivacyPolicyView.vue";
import TermsView from "@/components/sections/TermsOfUserView.vue";
import Checkbox from '@/components/controls/CheckBox.vue';
import Button from "@/components/controls/ButtonControl.vue"

export default defineComponent({
    components:{
        Privacy,
        TermsView,
        Button,
        Checkbox
    },
    data(){
        return{
            show_terms: false,
            button_text:"NEXT",
            aggree:false,
        }
    },
    methods:{
        showTerms(){
            this.show_terms = true;
            this.button_text = "PROCEED";
        },
        proceed(){
            this.$router.push('/application');
        },
        update(e){
            this.aggree = e;
        },
    }
})
</script>

<style scoped>

</style>