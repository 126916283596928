<template>
    <div class="w-full">
      <div class="relative" :class="[{'hasError':required && isEmpty},{ 'field_date--focused': isFocused }]">
        <a-date-picker :show-time="show_time?{ format: 'hh:mm a' }:null" class="static_height" ref="datePicker" placeholder=" " v-model:value="value1" :class="['noError',
        ]" @openChange="isFocused = true"
        @blur="isFocused = false" :size="'large'" :format="show_time?'MM/DD/YYYY hh:mm A':'MM/DD/YYYY'"
        v-bind:disabled-date="disabledDates"
        :allowClear="false"
        @change="onChange"
        />
        <div
          @click="clickPlaceholder"
          :class="[
              'input-field__placeholder',{'input-field__placeholder--focused':floatOn,'flex flex-row':required},
              { 'input-field__placeholder--focused': floatOn }
          ]"
          >
          <div v-if="required" class=" flex text-red-600 pt-1 pr-1">
            <span v-text="'*'"  class="self-center"/>
          </div>
          <span v-text="placeholder"/>
        </div>  
      </div>
      <div v-if="required && this.ageLimit" class="text-red-500">
          Age should be 18 years old or more
      </div>
    </div>
</template>
<script>
import { defineComponent} from 'vue';
import moment from 'moment';
export default defineComponent({
  data() {
    return {
      isEmpty: false,
      isFocused:false,
      value1: '',
      ageLimit:false,
    };
  },
  mounted() {
    if(this.submittedValue) {
        this.value1 = moment(this.submittedValue);
    }
  },
  props: {
    required:{
        type: Boolean,
        required: false,
    },
    show_time:{
      type:Boolean,
      required: false,
    },
    componentId: {
      type: String,
      required: true,
    },
    dates:{
      type:Array,
    },
    placeholder:{
      type: String,
      required: true,
    },
    submittedValue: {
      type: String,
      required: false,
    },
    interview: {
      type:Boolean,
      required:false
    }
  },
  computed: {
        floatOn() {
          if(this.value1){
            return this.isFocused || this.value1.toString().length > 0;
          }else {
            return this.isFocused;
          }
        },
    },
  methods:{
    disabledDates(current) {
      let that = this;
      // return current.diff(Date.now(), 'days')<=-1 || current.diff(Date.now(), 'days') % 7 === 4 || current.diff(Date.now(), 'days') % 7 === 3;
      if(that.interview) {
        if(that.dates.length == 0){
          return true;
        }else{
          return !that.dates.includes(moment(current.toString()).format('YYYY-MM-DD'));
        }
      }else{
        return '';
      }
      
    },
    clearDate(){
      this.value1 = '';
      this.$emit('changed',{
            key: this.componentId,
            value:'',
          });
    },
    onChange(val)
    {
      var selectedDate = new Date(val);
      var currentDate = new Date(Date.now());
      this.checkEmpty();
      if(!this.interview) {
        var diff = currentDate.getFullYear() - selectedDate.getFullYear();
        if(diff < 18) {
          this.$emit('error',this.componentId);
          this.isFocused = false;
          this.ageLimit = true;
        }else {
          this.$emit('clearError',this.componentId);
          this.ageLimit = false;
          this.$emit('changed',{
            key: this.componentId,
            value:this.show_time?moment(selectedDate).format("MM/DD/YYYY hh:mm A"):moment(selectedDate).format("YYYY-MM-DD"),
          });
        }
      } else {
         this.$emit('changed',{
            key: this.componentId,
            value:this.show_time?moment(selectedDate).format("MM/DD/YYYY hh:mm A"):moment(selectedDate).format("YYYY-MM-DD"),
          });
      }
    },
    checkAgeLimit(selectedDate) {
         var selected = new Date(selectedDate);
         var currentDate = new Date(Date.now());
         var diff = currentDate.getFullYear() - selected.getFullYear();

        if(diff < 18) {
          this.$emit('error',this.componentId);
          this.isFocused = false;
          this.ageLimit = true;
        } else {
          this.$emit('clearError',this.componentId);
          this.ageLimit = false;
        }
    },
    checkEmpty() {
        if (!this.value1) {
          this.$emit('error',this.componentId);
          this.isEmpty = true;
        }
        else {
          this.isEmpty = false;
          this.$emit('clearError',this.componentId);
        }
    },
    
    clickPlaceholder() {
      this.isFocused = true;
    },
  },
});
</script>
<style scoped>
.noError {
  width:100%; 
  border-radius:5px; 
 
}
.hasError {
  width:100%; 
  border-radius:5px; 
  border:1px solid red !important;
}
.field_date--focused {
  border-radius: 5px !important;
  border: 1px solid #016b00 !important;
}
.static_height {
  border: #CCCCCC solid !important;
  border-width:thin !important;
  height: 55px !important;
}
.input-field__input {
  width: 100%;
  height: 55px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border: 2px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__static {
  width: 100%;
  margin-top: -10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  border-right: 2px solid #bcbcbc;
  outline: none;
}
.input-field__input--error {
  border: 2px solid #f00f0f !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.input-field-wrapper {
  position: relative;
  padding-top: 10px;
}
.input-field__input {
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  border: 2px solid #bcbcbc;
  border-radius: 5px;
  outline: none;
}
.input-field__input--focused {
  border: 2px solid #016b00;
}
.input-field__input--empty {
  border: 2px solid #db0404;
}
.input-field__placeholder {
  position: absolute;
  font-size: 18px;
  left: 12px;
  top: 20px;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field-number__placeholder {
  position: absolute;
  font-size: 18px;
  left: 85px;
  top: 23px;
  padding: 0 5px;
  background: transparent;
  transition: all 0.3s;
  line-height: 12px;
}
.input-field__placeholder--focused {
  top: 5px;
  font-size: 14px;
  left: 2px;
}
.input-field-number__placeholder--focused {
  top: 5px;
  font-size: 14px;
  left: 85px;
}
.peek-margin {
    margin-top: 11px;
}
</style>