<template>
    <div class="border-2 bg-white rounded shadow-lg w-full relative" :class="[max_width,background,{'gradient':gradient},max_height]">
        <div class="w-full" v-if="Logo">
             <img src="https://pafcpic.ph/sites/all/themes/pafcpic/logo.png" class="w-full p-5" />
        </div>
        <div v-if="title" class="border-b flex xs:flex-col border-gray-200 p-3 font-bold text-xl xs:text-xl" :class="[text_color]">
            <span v-text="title_morph()"/>
            <div class="grow"></div>
            <span v-text="'MEMBERSHIP TYPE:'" v-if="service_type"/>
            <span v-text="service_type" v-if="service_type" class="text-theme-primary pl-1"/>
            <!-- <span v-for="(index, items) in dates" :id="items" :key="items" v-text="index"/> -->
        </div>
        <div class="w-full flex place-content-center" :class="direction">
            <slot></slot>
        </div>
    </div>
</template>
<script>
// import moment from 'moment';
import { defineComponent } from 'vue'

export default defineComponent({
 props:{
    gradient:{
        type:Boolean
    },
    max_height:{
        type: String,
        required: false,
    },
    max_width: {
        type: String,
        required: false,
    },
    text_color:{
        type: String,
    },
    title:{
        type:String,
        required:false,
    },
    service_type:{
        type:String,
        required:false,
    },
    Logo: {
        type:Boolean,
        required:false,
    },
    background:{
        type:String,
        required:false,
    },
    direction:{
        type:String,
        required:false,
    },
    // test_date:{
    //     type:Date,
    //     required:false,
    // }
 },
 data(){
    return{
     date:null,
     dates:[],
    }
 },
 mounted(){
    // this.date = moment(new Date()).add(2,'hours').format('YYYY-MM-DDTHH:mm:ss');
    // let date1 = ['2024-07-18T18:39:15','2024-07-18T18:35:15','2024-07-18T18:43:15','2024-07-18T18:45:15'];
   
    // date1.forEach(element => {
    //     if(moment(element).format('YYYY-MM-DDTHH:mm:ss')>this.date){
    //         this.dates.push(moment(element).format('YYYY-MM-DDTHH:mm:ss'))
    //     }
    // });
    //  console.log(this.dates);
 },
 methods:{
    title_morph() {
        return this.title.toUpperCase();
    }
 }
})
</script>
<style scoped>
.gradient {
    background-image: linear-gradient(to bottom right,#01A500, #FEE765);
}
</style>
